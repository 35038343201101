<template>
  <div>
    <Loading v-show="loading"></Loading>
    <div
      v-if="userData.searchType === 1 && !isEmpty(userData.searchType)"
      id="chart"
    >
      <div
        ref="lineChart"
        :style="{ width: '100%', height: '400px'}"
      ></div>
      <div
        class="d-flex align-left flex-wrap"
      >
        <v-spacer></v-spacer>
        <v-switch
          style="width:250px"
          v-model="countDisplayswitch"
          flat
          :label="countDisplayswitch ? `Count Display`:`Count Undisplayed`"
          hide-details
          @change="countDisplay"
        ></v-switch>
      </div>
    </div>
    <br>
    <div
      v-if="!isEmpty(userData.searchType)"
      id="mainTable"
      class="report-table"
    >
      <v-row>
        <v-col
          v-if="userData.searchType === 1 && !isEmpty(userData.searchType)"
          cols="12"
          md="3"
        >
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    Time
                  </th>
                  <th class="text-center">
                    Staying People
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in userData.stayData"
                  :key="item.time"
                >
                  <td class="text-center">
                    {{ item.time }}
                  </td>
                  <td class="text-right">
                    {{ String(item.count).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col
          v-if="userData.searchType === 2 && !isEmpty(userData.searchType)"
          cols="12"
          md="9"
        >
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    Time
                  </th>
                  <th class="text-center Sun">
                    Sun
                  </th>
                  <th class="text-center">
                    Mon
                  </th>
                  <th class="text-center">
                    Tue
                  </th>
                  <th class="text-center">
                    Wed
                  </th>
                  <th class="text-center">
                    Thu
                  </th>
                  <th class="text-center">
                    Fri
                  </th>
                  <th class="text-center Sat">
                    Sat
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in userData.stayData"
                  :key="item.time"
                >
                  <td class="text-center">
                    {{ item.time }}
                  </td>
                  <td class="text-right Sun">
                    {{ item.sunCount }}
                  </td>
                  <td class="text-right">
                    {{ item.monCount }}
                  </td>
                  <td class="text-right">
                    {{ item.tueCount }}
                  </td>
                  <td class="text-right">
                    {{ item.wedCount }}
                  </td>
                  <td class="text-right">
                    {{ item.thuCount }}
                  </td>
                  <td class="text-right">
                    {{ item.friCount }}
                  </td>
                  <td class="text-right Sat">
                    {{ item.satCount }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mdiDownloadOutline, mdiFileExportOutline } from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'
import 'echarts/lib/chart/line'
import store from '@/store'
import stayStoreModule from './stayStoreModule'
import { isEmpty, getSessionData, date2yyyymmddhhmmss } from '@core/utils'
import eventBus from '@core/utils/eventBus'
import Loading from '@/views/components/load/Loading.vue'

const USER_APP_STORE_MODULE_NAME = 'app-stay'

const currentDate = new Date()
const userData = ref({})
const echarts = require('echarts')

export default {
  components: { Loading },
  watch: {
    // userData を watch する
    userData(val) {
      setTimeout(() => {
        if (!isEmpty(val.seriesData)) {
          this.drawLine(val)
        }
      }, 500)
    },
  },
  created() {
    const startDate = sessionStorage.getItem('searchDate')
    const endDate = sessionStorage.getItem('searchDate')
    this.searchOperation(new Date(startDate), new Date(endDate))
  },
  mounted() {
    eventBus.$on('peopleCountSearch', () => {
      const startDate = sessionStorage.getItem('searchDate')
      const endDate = sessionStorage.getItem('searchDate')
      this.searchOperation(new Date(startDate), new Date(endDate))
    })

    // 日付選択コンポーネントを表示させる
    this.$dateRangePickerDisplayFlag = false
    this.$selectScDisplayFlag = true
    this.$selectDomesticScDisplayFlag = false
    this.$domesticDatePickerDisplayFlag = false

    this.$datePickerDisplayFlag = true
  },
  beforeDestroy() {
    // eventBus リスナーの削除
    eventBus.$off('peopleCountSearch')
  },
  methods: {
    // 検索処理を行う
    searchOperation(startDate, endDate) {
      this.loading = true
      const startDateFormatted = `${startDate.getFullYear()}
      -${(startDate.getMonth() + 1).toString().padStart(2, '0')}
      -${startDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const endDateFormatted = `${endDate.getFullYear()}
      -${(endDate.getMonth() + 1).toString().padStart(2, '0')}
      -${endDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      const siteId = String(getSessionData('data').siteId)
      const searchStartDate = startDateFormatted
      const searchEndDate = endDateFormatted
      store
        .dispatch('app-stay/fetchData', {
          params: {
            siteId,
            searchStartDate,
            searchEndDate,
          },
        })
        .then(response => {
          userData.value = response.data.result.data
          userData.value.labelShow = this.countDisplayswitch
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          console.log(error)
          this.$router.push({ name: 'error-login' })
        })
    },
    countDisplay() {
      this.userData.labelShow = this.countDisplayswitch
      this.drawLine(this.userData)
    },

    // line chart作成する
    drawLine(val) {
      const lineChartOption = {
        title: {
          left: '15%',
          text: val.text,
        },
        toolbox: {
          bottom: 0,
          left: 20,
          feature: {
            saveAsImage: { title: 'Save Image', name: `${date2yyyymmddhhmmss(new Date())}`, type: 'jpeg' },
          },
        },
        tooltip: { trigger: 'axis' },
        grid: {
          left: '8%',
          right: '28%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            axisTick: {
              alignWithLabel: true,
            },
            data: val.legendData,
          },
        ],
        yAxis: [
          {
            splitNumber: 10,
          },
        ],
        series: [
          {
            type: 'line',
            label: {
              normal: {
                show: val.labelShow,
                formatter: params => {
                  const v = parseFloat(params.value)

                  return String(v).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
                },
              },
            },
            data: val.seriesData[0].data,
          },
        ],
      }
      let lineChart = echarts.getInstanceByDom(this.$refs.lineChart)
      if (lineChart == null) {
        lineChart = echarts.init(this.$refs.lineChart)
      }

      lineChart.setOption(lineChartOption, true)
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, stayStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const startDate = sessionStorage.getItem('searchDate')
    if (isEmpty(startDate)) {
      const startDateFormatted = `${currentDate.getFullYear()}
      -${(currentDate.getMonth() + 1).toString().padStart(2, '0')}
      -${currentDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')

      sessionStorage.setItem('searchDate', startDateFormatted)
    }

    const countDisplayswitch = ref(true)

    return {
      countDisplayswitch,
      userData,
      icons: {
        mdiDownloadOutline,
        mdiFileExportOutline,
      },
      isEmpty,
      loading: false,
    }
  },
}
</script>
<style scoped>
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}
.v-data-table thead .Sat {
  background-color: rgb(204, 255, 255) !important;
}
.v-data-table thead .Sun {
  background-color: rgb(255, 204, 255) !important;
}

.v-data-table tbody .Sat {
  background-color: rgb(204, 255, 255);
}
.v-data-table tbody .Sun {
  background-color: rgb(255, 204, 255);
}
.theme--light.v-data-table td {
  color: #000 !important;
  border: 1px solid rgba(94, 86, 105, 0.14);
}
.theme--light.v-data-table th {
  border: 1px solid rgba(94, 86, 105, 0.14);
}
</style>
